// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography, Icon, Divider } from '@material-ui/core';
import ManualIcon from '@material-ui/icons/NatureTwoTone';

import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { condensedCase } from '../../utils/GeneralUtils';
import {
  getDriveTypeIconFromMove,
  getCustomerNameFromMove,
  getRegionNameFromMove,
  getFormattedVehicleFromMove,
  checkForConciergeTag,
  checkForGroupableTag,
  checkForManualTag,
  checkForNotesTag,
  checkForAnyTag,
  checkForRailyardTag,
} from '../../utils/MoveUtils';

import MoveTileHangtag from '../../pages/Plans-New/components/MoveTileHangtag';

import { buildDefaultDriveActions } from './MoveActions';
import DriveTooltip from './DriveTooltip';
import {
  MoveTagConciergeTooltip,
  MoveTagGroupableTooltip,
  MoveTagManualTooltip,
  MoveTagNotesTooltip,
} from './MoveTagTooltips';

// -------------------------------- COMPONENT -------------------------------- //

export default function StaticDriveTile({
  move,
  zIndex = 1,
  isSelectable,
  isSelected,
  onSelected,
  isGroupable,
  onCustomGroupCheck,
  groupableMoveIds,
  isPlaceholder,
  timezoneOverride = dayjs.tz.guess(),
  ...props
}) {
  const cls = useStyles();

  // Handle tag checks
  const hasConciergeTag = checkForConciergeTag(move);
  const hasGroupableTag = checkForGroupableTag(move, isGroupable, onCustomGroupCheck, groupableMoveIds);
  const hasManualTag = checkForManualTag(move);
  const hasNotesTag = checkForNotesTag(move);
  const hasRailyardTag = checkForRailyardTag(move);
  const hasAnyTag = checkForAnyTag(move, isGroupable, onCustomGroupCheck, groupableMoveIds);

  // Get actions
  const actions = buildDefaultDriveActions(move);

  /** Get tile CSS classes */
  const getTileClasses = () => {
    let classes = [cls.tile];

    if (isSelectable) classes.push(cls.tileSelectable);
    if (isSelected) classes.push(cls.tileSelected);

    return clsx(classes);
  };

  /** Get drive CSS classes */
  const getDriveClasses = () => {
    let classes = [cls.drive];

    if (move?.consumer_type === `customer`) classes.push(cls.backgroundConcierge);
    else if (move?.consumer_type === `loaner`) classes.push(cls.backgroundLoaner);
    else classes.push(cls.backgroundOps);

    return clsx(classes);
  };

  /** Handle move selection */
  const handleSelectMove = () => {
    if (onSelected) onSelected(move?.id);
  };

  // Return component
  return (
    <>
      <ContextMenuTrigger id={`static-drive-${move?.id}-cm`} source={`static-drive-${move?.id}-cm`}>
        {/* SHELL */}
        <div className={cls.shell} style={{ zIndex: zIndex }} onClick={() => handleSelectMove()} {...props}>
          {/* TILE */}
          <div className={getTileClasses()}>
            {/* DRIVE */}
            <div className={getDriveClasses()}>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <DriveTooltip move={move}>
                    <Icon className={cls.driveIcon}>{isSelected ? `check_box` : getDriveTypeIconFromMove(move)}</Icon>
                  </DriveTooltip>
                </Grid>

                <Grid item>
                  <Typography className={cls.idTxt}>{move?.id ? `#${move?.id}` : `-`}</Typography>
                </Grid>

                <Grid item xs />

                <Grid item>
                  <Typography className={cls.timeTxt}>
                    {move?.ready_by ? dayjs(move.ready_by).tz(timezoneOverride).format('M/D/YYYY h:mm A z') : `-`}
                  </Typography>
                </Grid>
              </Grid>

              <div className={cls.spacer_md} />

              <Grid container alignItems='flex-start'>
                <Grid item>
                  <Typography className={cls.customerTxt}>{getCustomerNameFromMove(move)}</Typography>
                </Grid>

                <Grid item xs />

                <Grid item>
                  <Typography className={cls.regionTxt}>{getRegionNameFromMove(move)}</Typography>
                </Grid>
              </Grid>

              <div className={cls.spacer_xl} />

              <Grid container spacing={1} alignItems='flex-end'>
                <Grid item xs>
                  <Typography className={cls.addressTxt}>{move?.lane?.pickup?.name || `Unknown Pickup`}</Typography>
                  <div className={cls.spacer_xs} />
                  <Typography className={cls.addressTxt}>
                    to {move?.lane?.delivery?.name || `Unknown Delivery`}
                  </Typography>

                  <div className={cls.spacer_xl} />

                  <Typography className={cls.vehicleTxt}>{getFormattedVehicleFromMove(move)}</Typography>
                </Grid>

                {/* TAGS */}
                <Grid item>
                  {hasAnyTag ? (
                    <div className={cls.tagGrid}>
                      {hasGroupableTag ? (
                        <MoveTagGroupableTooltip move={move}>
                          <Icon className={cls.tagIcon}>hub</Icon>
                        </MoveTagGroupableTooltip>
                      ) : null}

                      {hasConciergeTag ? (
                        <MoveTagConciergeTooltip move={move}>
                          <Icon className={cls.tagIcon}>person</Icon>
                        </MoveTagConciergeTooltip>
                      ) : null}

                      {hasManualTag ? (
                        <MoveTagManualTooltip move={move}>
                          <ManualIcon className={cls.tagIcon} />
                        </MoveTagManualTooltip>
                      ) : null}

                      {hasNotesTag ? (
                        <MoveTagNotesTooltip move={move}>
                          <Icon className={cls.tagIcon}>assignment</Icon>
                        </MoveTagNotesTooltip>
                      ) : null}
                    </div>
                  ) : null}
                </Grid>

                {/* RAILYARD HANGTAG */}
                {hasRailyardTag ? (
                  <Grid item>
                    <MoveTileHangtag hangtag={move?.hangtags?.[0]} parent={`static-drive-tile`} />
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </div>
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={`static-drive-${move?.id}-cm`}>
        {actions?.map((action, i) => {
          if (!action || action?.hide) return null;
          if (!action?.handler) return <Divider key={`static-drive-action-${i}-cm`} className={cls.cmDivider} />;
          return (
            <ContextMenuItem
              key={`static-drive-action-${condensedCase(action?.label)}-cm`}
              data={{ action: action, ...action?.data }}
              disabled={typeof action?.disabled === 'function' ? action?.disabled(move) : action?.disabled}
              onClick={() => action?.handler(move)}
            >
              {action?.label}
            </ContextMenuItem>
          );
        })}
      </ContextMenu>
    </>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  shell: {
    position: 'relative',
  },

  tile: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
    cursor: 'pointer',
    transition: `all 0.15s`,
  },
  tileSelectable: {
    '&:hover, &:focus': {
      transform: `scale(1.025)`,
    },
    '&:active': {
      opacity: 0.667,
    },
  },
  tileSelected: {
    boxShadow: 'inset 0 0 9px 3px #ffffff40',
    opacity: 0.667,
  },

  drive: {
    position: 'relative',
    overflow: 'hidden',
    padding: 8,
    color: '#ffffff',
  },

  driveIcon: {
    display: 'block',
    fontSize: 24,
    color: theme.palette.text.contrast,
  },

  idTxt: {
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 900,
  },
  timeTxt: {
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 900,
  },
  customerTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 900,
  },
  regionTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 900,
    color: theme.palette.text.contrastFade,
  },
  addressTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 400,
  },
  vehicleTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.contrastFade,
  },

  tagGrid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: 4,
    borderRadius: 4,
    backgroundColor: '#ffffff40',
  },
  tagIcon: {
    display: 'block',
    fontSize: 20,
    color: '#ffffff',
  },

  backgroundConcierge: {
    backgroundColor: theme.palette.concierge.main,
  },
  backgroundLoaner: {
    backgroundColor: theme.palette.concierge.loaner,
  },
  backgroundOps: {
    backgroundColor: theme.palette.ops.main,
  },

  colorConcierge: {
    color: theme.palette.concierge.main,
  },
  colorLoaner: {
    color: theme.palette.concierge.loaner,
  },
  colorOps: {
    color: theme.palette.ops.main,
  },

  fadeConcierge: {
    background: `linear-gradient(90deg, ${theme.palette.concierge.main}00, ${theme.palette.concierge.main})`,
  },
  fadeLoaner: {
    background: `linear-gradient(90deg, ${theme.palette.concierge.loaner}00, ${theme.palette.concierge.loaner})`,
  },
  fadeOps: {
    background: `linear-gradient(90deg, ${theme.palette.ops.main}00, ${theme.palette.ops.main})`,
  },

  cmDivider: {
    marginTop: 4,
    marginBottom: 4,
  },

  spacer_xs: {
    height: 2,
    width: '100%',
  },
  spacer_sm: {
    height: 3,
    width: '100%',
  },
  spacer_md: {
    height: 4,
    width: '100%',
  },
  spacer_lg: {
    height: 6,
    width: '100%',
  },
  spacer_xl: {
    height: 8,
    width: '100%',
  },
}));
