// react-toastify playground - https://fkhadra.github.io/react-toastify/introduction/

//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles, CssBaseline } from '@material-ui/core';

import Nav from './Nav';
import ScrollToTop from './ScrollToTop';
// import { legacyTimelineStuff } from "../../providers/DataProvider";

//////////////////////// COMPONENT ////////////////////////

function Layout(props) {
  const cls = useStyles();
  const scrollRef = React.useRef(null);
  const [scrollElement, setScrollElement] = React.useState(null);

  React.useEffect(() => {
    if (scrollRef && scrollRef.current) setScrollElement(scrollRef.current);
  }, [scrollRef]);

  // In order to get the legacy timeline working, we need to get
  // a reference to the <main> tag from here into timeline.js
  // Once we rewrite timeline, we can do away with this code and
  // the ref on <main>
  // Once legacy timeline is removed, you can remove the import from
  // DataProvider and the ref added to <main> below.
  // - Rob 7/21/2021
  // =====
  const mainRef = React.useRef(null);
  React.useEffect(() => {
    if (mainRef && mainRef.current) {
      //legacyTimelineStuff.mainRef = mainRef.current;
    }
  }, [mainRef]);
  // =====

  return (
    <>
      <CssBaseline />

      <Nav />

      <main id='main' className={cls.content} ref={mainRef}>
        <div className={cls.body}>
          <div className={cls.navPad} />
          <div ref={scrollRef} className={cls.page}>
            {props.children}
          </div>
        </div>
      </main>

      <ToastContainer position='top-center' />

      {scrollElement ? <ScrollToTop element={scrollElement} /> : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  content: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  body: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    maxHeight: '100%',
  },
  navPad: {
    position: 'relative',
    width: '100%',
    minHeight: 64,
    maxHeight: 64,
  },
  page: {
    flex: 1,
    position: 'relative',
    width: '100%',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default Layout;
