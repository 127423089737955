// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Icon, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useEngine } from '../../hooks/useEngine';
import { useTools } from '../../hooks/useTools';
import useDriverPay from './useDriverPay';

import TopUpModal from '../../components/ManageTopUp/TopUpModal';

// COMPONENT ---------------------------------------------------------------- //

export default function DriverPayHeader({ featuredPaycycle }) {
  const cls = useStyles();

  const { fetchBalanceAmount } = useEngine();
  const { goToPayCycleDetails, formatUSD } = useTools();
  const {
    getDriverCountFromPaycycle,
    getMoveCountFromPaycycle,
    getPaymentCountFromPaycycle,
    getChargeCountFromPaycycle,
    getAmountPaidFromPaycycle,
    getAmountDueFromPaycycle,
  } = useDriverPay();

  const [balanceAmount, setBalanceAmount] = React.useState(0);
  const [loadingBalance, setLoadingBalance] = React.useState(true);
  const [errorBalance, setErrorBalance] = React.useState(false);

  // Top-Up Modal
  const [topUpModal, setTopUpModal] = React.useState({ open: false, input: null, output: null });
  const handleTopUpModalOpen = (input = null) => {
    setTopUpModal({ open: true, input: input, output: null });
  };
  const handleTopUpModalClose = async (output = null) => {
    setTopUpModal({ open: false, input: null, output: output });
  };

  // Fetch our HopDrive wallet balance
  React.useEffect(() => {
    const fetchBalance = async () => {
      setLoadingBalance(true);

      const newBalance = await fetchBalanceAmount();
      if (newBalance === null) setErrorBalance(true);

      setBalanceAmount(newBalance);
      setLoadingBalance(false);
    };
    fetchBalance();
  }, []);

  const amount = featuredPaycycle
    ? getAmountDueFromPaycycle(featuredPaycycle) - getAmountPaidFromPaycycle(featuredPaycycle)
    : 0;
  const driverCount = featuredPaycycle ? getDriverCountFromPaycycle(featuredPaycycle) : 0;
  const moveCount = featuredPaycycle ? getMoveCountFromPaycycle(featuredPaycycle) : 0;
  const paymentCount = featuredPaycycle ? getPaymentCountFromPaycycle(featuredPaycycle) : 0;
  const chargeCount = featuredPaycycle ? getChargeCountFromPaycycle(featuredPaycycle) : 0;

  return (
    <>
      <TopUpModal
        open={topUpModal.open}
        input={topUpModal.input}
        onClose={handleTopUpModalClose}
        balance={balanceAmount}
      />

      <div className={cls.header}>
        <div className={cls.flexHeader}>
          <div className={cls.flexHeaderItem}>
            <Typography className={cls.headerTxt}>Featured Pay Cycle</Typography>

            <Typography className={cls.subheaderTxt}>
              {!featuredPaycycle
                ? `No featured pay cycle found`
                : `${
                    featuredPaycycle ? dayjs.utc(dayjs(featuredPaycycle?.start_date)).format(`MMMM Do`) : ``
                  }\xa0-\xa0${
                    featuredPaycycle ? dayjs.utc(dayjs(featuredPaycycle?.end_date)).format(`MMMM Do, YYYY`) : ``
                  }`}
            </Typography>

            <div className={cls.flex}>
              <Typography className={cls.amountTxt}>
                {formatUSD(amount, { displayAccountingNegative: true })}
              </Typography>

              {featuredPaycycle ? (
                <Button
                  disableElevation
                  variant='contained'
                  size='large'
                  color='primary'
                  onClick={() => goToPayCycleDetails(featuredPaycycle?.id)}
                >
                  <Icon className={cls.btnIcon}>visibility_on</Icon>
                  View
                </Button>
              ) : null}
            </div>

            <div className={cls.flex}>
              <Typography className={cls.detailTxt}>
                {driverCount} Driver{driverCount === 1 ? `` : `s`}
              </Typography>

              <Typography className={cls.detailTxt}>
                {moveCount} Move{moveCount === 1 ? `` : `s`}
              </Typography>

              <Typography className={cls.detailTxt}>
                {paymentCount} Payment{paymentCount === 1 ? `` : `s`}
              </Typography>

              <Typography className={cls.detailTxt}>
                {chargeCount} Charge{chargeCount === 1 ? `` : `s`}
              </Typography>
            </div>
          </div>

          <div className={cls.flexHeaderItem}>
            <Typography className={cls.headerTxt}>Available Balance</Typography>

            <Typography className={cls.subheaderTxt}>Add funds to HopDrive's wallet to pay drivers</Typography>

            <div className={cls.flex}>
              <Typography className={cls.amountTxt}>
                {errorBalance
                  ? `Error!`
                  : loadingBalance
                  ? `Fetching...`
                  : formatUSD(balanceAmount, { displayAccountingNegative: true })}
              </Typography>

              <Button
                disableElevation
                variant='contained'
                size='large'
                color='tertiary'
                onClick={() => handleTopUpModalOpen()}
              >
                <Icon className={cls.btnIcon}>currency_exchange</Icon>
                Top-Up
              </Button>
            </div>

            <Typography className={cls.detailTxt}>
              {errorBalance
                ? `Our 3rd party banking system is currently having issues`
                : `Fill this balance enough for 2 cycles`}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  header: {
    zIndex: 8,
    position: 'relative',
    width: '100%',
    padding: 48,
    background: theme.palette.text.primary,
    boxShadow: theme.shadow.nav,
    animation: `$fadeIn 0.667s`,
  },
  headerTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.contrastPrimary,
  },
  subheaderTxt: {
    marginBottom: 12,
    lineHeight: 1,
    fontSize: 16,
    color: theme.palette.text.contrastSecondary,
  },
  amountTxt: {
    lineHeight: 1,
    fontSize: 56,
    fontWeight: 900,
    color: theme.palette.text.contrastPrimary,
  },
  detailTxt: {
    marginTop: 16,
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.contrastPrimary,
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  flexHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 48,
  },
  flexHeaderItem: {
    position: 'relative',
    width: 'fit-content',
  },

  btnIcon: {
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
