//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';

import { gql, useMutation } from '@apollo/client';
import { useTools } from '../../hooks/useTools';

import { makeStyles, Icon, Typography, Checkbox, TextField, InputAdornment } from '@material-ui/core';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../ModalComponents';
import Spacer from '../Spacer';

const UPDATE_MOVE_DISCOUNT = gql`
  mutation update_move_discount($moveId: bigint!, $discountAmount: numeric, $discountReason: String) {
    update_moves(
      where: { id: { _eq: $moveId } }
      _set: { discount_amount: $discountAmount, discount_reason: $discountReason, updatedat: "now()", disputed: false }
    ) {
      affected_rows
      returning {
        id
        active
        discount_amount
        discount_reason
        updatedat
      }
    }
  }
`;

//////////////////////// COMPONENT ////////////////////////

function ARReviewContent(props) {
  const cls = useStyles();
  const { capFirst } = useTools();

  const { open, onClose, input } = props;

  const [updateMoveDiscount] = useMutation(UPDATE_MOVE_DISCOUNT);

  const [isDiscounted, setIsDiscounted] = React.useState(false);
  const [discountedAmount, setDiscountedAmount] = React.useState(0);
  const [discountedReason, setDiscountedReason] = React.useState(``);

  React.useEffect(() => {
    if (open && input) {
      setIsDiscounted(input.discount_amount > 0 || false);
      setDiscountedAmount(input.discount_amount || 0);
      setDiscountedReason(input.discount_reason || ``);
    }
  }, [open, input]);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const handleSubmit = async () => {
    if (discountedAmount <= input.due_amount && discountedAmount >= 0) {
      try {
        const moveId = input.move_id || input.move ? input.move.id : null;
        const res = await updateMoveDiscount({
          variables: {
            moveId: moveId || null,
            discountAmount: discountedAmount || 0,
            discountReason: discountedReason || null,
          },
        });
        if (res && res.data && res.data.update_moves && res.data.update_moves.affected_rows > 0) {
          toast.success(`Successfully updated AR record!`);
          handleClose({
            isDiscounted: isDiscounted,
            discountedAmount: discountedAmount,
            discountedReason: discountedReason,
          });
        }
      } catch (err) {
        toast.error(`Failed to update discount!`);
        console.error(`Failed to update discount:`, err);
      }
    } else {
      toast.warning(`Discounted Amount must be greater than 0 and cannot be greater than the Due Amount!`);
    }
  };
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
    setIsDiscounted(false);
    setDiscountedAmount(0);
    setDiscountedReason(``);
  };

  return (
    <>
      <ModalHeader handleClose={handleClose}>Review</ModalHeader>

      <ModalContent
        subtitle={
          !input.disputed
            ? !isDiscounted
              ? `Since there is no dispute associated with this move, no review is necessary unless you add a discount. To add a discount, click the "Add Discount" checkbox.`
              : `By adding a discount, the current record of the move will be stored as a revision. The discount will be added to the new version of the move and displayed to the customer.`
            : !isDiscounted
            ? `By clearing a dispute, the current record of the move will be stored as a revision and the move will be no longer be flagged for review. To add a discount, click the "Add Discount" checkbox.`
            : `By adding a discount and clearing a dispute, the current record of the move will be stored as a revision and the move will be no longer be flagged for review. The discount will be added to the new version of the move and displayed to the customer.`
        }
      >
        {input.disputed ? (
          <>
            <div className={cls.disputeBox}>
              <Typography className={cls.disputeHead}>Dispute Reason: </Typography>
              <Typography className={cls.disputeBody}>{capFirst(input.dispute_reason)}</Typography>
            </div>
            <Spacer />
          </>
        ) : null}

        <Typography className={cls.title + ' ' + cls.inline}>Add Discount</Typography>
        <Checkbox
          className={cls.checkbox + ' ' + cls.inline}
          checked={isDiscounted}
          value={isDiscounted}
          onChange={() => setIsDiscounted(!isDiscounted)}
          color='primary'
        />

        {isDiscounted ? (
          <>
            <Spacer />
            <TextField
              required
              type='number'
              label='Amount'
              placeholder='0.00'
              variant='outlined'
              size='small'
              value={discountedAmount}
              onChange={handleInputChange(setDiscountedAmount)}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : null}

        {input.disputed || isDiscounted ? (
          <>
            <Spacer />
            <TextField
              required
              fullWidth
              label='Reason'
              placeholder={
                isDiscounted
                  ? 'Enter a brief reason for the discount...'
                  : 'Enter a brief reason for clearing the dispute...'
              }
              variant='outlined'
              size='small'
              value={discountedReason}
              onChange={handleInputChange(setDiscountedReason)}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      comment
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : null}
      </ModalContent>

      <ModalFooter>
        <ModalAction
          disabled={!isDiscounted && discountedReason.trim().length < 1}
          color='primary'
          onClick={() => handleSubmit()}
        >
          {isDiscounted && input.disputed ? 'Submit & Clear' : isDiscounted ? 'Submit Discount' : 'Clear Dispute'}
        </ModalAction>
        <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  disputeBox: {
    padding: theme.spacing(1),
    border: theme.border[0],
    borderRadius: theme.shape.borderRadius,
  },
  disputeHead: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 600,
  },
  disputeBody: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 400,
  },
  checkbox: {
    verticalAlign: '-13.5%',
    marginLeft: theme.spacing(1),
  },
  inline: {
    display: 'inline-block',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default ARReviewContent;
