// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Tooltip } from '@material-ui/core';

import { capFirst, getCleansedPhoneNumber } from '../../utils/GeneralUtils';
import {
  getDriveTypeFromMove,
  getFormattedStatusFromDrive,
  getFormattedCancelStatusFromDrive,
  getDriverNameAndIdFromMove,
  getCustomerNameAndIdFromMove,
  getOrganizationNameAndIdFromMove,
  getRegionNameAndIdFromMove,
  getFormattedLaneDistanceFromMove,
  getFormattedLaneDurationFromMove,
  getFormattedVehicleFromMove,
  getFormattedTransmissionFromMove,
  getFormattedActiveFlagFromMove,
  getFormattedChargeableFlagFromMove,
  getFormattedPayableFlagFromMove,
  getFormattedSettledFlagFromMove,
} from '../../utils/MoveUtils';

// -------------------------------- COMPONENT -------------------------------- //

export default function MoveTooltip({ children, move, debug = false }) {
  const cls = useStyles();

  return (
    <Tooltip
      title={
        <Grid container spacing={2} wrap='nowrap' className={cls.ttRoot}>
          <Grid item xs className={cls.ttList}>
            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Internal Move ID:</div>
              <div className={cls.ttValBold}>{move?.id ? `#${move?.id}` : `-`}</div>
            </div>

            {move?.reference_num ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>External Reference ID:</div>
                <div className={cls.ttVal}>{move?.reference_num || `-`}</div>
              </div>
            ) : null}

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Drive Type:</div>
              <div className={cls.ttVal}>{capFirst(getDriveTypeFromMove(move))}</div>
            </div>

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Status:</div>
              <div className={cls.ttVal}>{getFormattedStatusFromDrive(move)}</div>
            </div>

            {move.cancel_status ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Cancel Status:</div>
                <div className={cls.ttVal}>{getFormattedCancelStatusFromDrive(move)}</div>
              </div>
            ) : null}

            <hr className={cls.ttDivider} />

            {move?.driver || move?.driver_id || move?.driver_name ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Driver:</div>
                <div className={cls.ttVal}>{getDriverNameAndIdFromMove(move)}</div>
              </div>
            ) : null}

            {move?.driver_app_version ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Driver App Version:</div>
                <div className={cls.ttVal}>{move?.driver_app_version || `-`}</div>
              </div>
            ) : null}

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Driver Status:</div>
              <div className={cls.ttVal}>{capFirst(move?.driver_status) || `-`}</div>
            </div>

            <hr className={cls.ttDivider} />

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Customer:</div>
              <div className={cls.ttVal}>{getCustomerNameAndIdFromMove(move)}</div>
            </div>

            {move?.customer?.organization ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Organization:</div>
                <div className={cls.ttVal}>{getOrganizationNameAndIdFromMove(move)}</div>
              </div>
            ) : null}

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Region:</div>
              <div className={cls.ttVal}>{getRegionNameAndIdFromMove(move)}</div>
            </div>

            <hr className={cls.ttDivider} />

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Internal Lane ID:</div>
              <div className={cls.ttValBold}>{move?.lane?.id ? `#${move?.lane?.id}` : `-`}</div>
            </div>

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>From:</div>
              <div className={cls.ttVal}>{move?.lane?.pickup?.name || `-`}</div>
            </div>

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>To:</div>
              <div className={cls.ttVal}>{move?.lane?.delivery?.name || `-`}</div>
            </div>

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Distance:</div>
              <div className={cls.ttVal}>{getFormattedLaneDistanceFromMove(move)}</div>
            </div>

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Duration:</div>
              <div className={cls.ttVal}>{getFormattedLaneDurationFromMove(move)}</div>
            </div>

            <hr className={cls.ttDivider} />

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Vehicle:</div>
              <div className={cls.ttVal}>{getFormattedVehicleFromMove(move)}</div>
            </div>

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Transmission:</div>
              <div className={cls.ttVal}>{getFormattedTransmissionFromMove(move)}</div>
            </div>

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>VIN:</div>
              <div className={cls.ttVal}>{move?.vehicle_vin || `-`}</div>
            </div>

            {move?.vehicle_stock ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Stock:</div>
                <div className={cls.ttVal}>{move?.vehicle_stock || `-`}</div>
              </div>
            ) : null}

            {getDriveTypeFromMove(move) === `concierge` ? (
              <>
                <hr className={cls.ttDivider} />

                {move?.consumer_name ? (
                  <div className={cls.ttFlex}>
                    <div className={cls.ttKey}>Consumer Name:</div>
                    <div className={cls.ttVal}>{move?.consumer_name || `-`}</div>
                  </div>
                ) : null}

                {move?.consumer_phone ? (
                  <div className={cls.ttFlex}>
                    <div className={cls.ttKey}>Consumer Phone:</div>
                    <div className={cls.ttVal}>{getCleansedPhoneNumber(move?.consumer_phone) || `-`}</div>
                  </div>
                ) : null}

                <div className={cls.ttFlex}>
                  <div className={cls.ttKey}>Concierge Type:</div>
                  <div className={cls.ttVal}>{move?.consumer_at_pickup ? `Pickup` : `Return`}</div>
                </div>
              </>
            ) : null}

            {move?.parent_move_id ? (
              <>
                <hr className={cls.ttDivider} />

                <div className={cls.ttFlex}>
                  <div className={cls.ttKey}>Parent Move ID:</div>
                  <div className={cls.ttValBold}>{move?.parent_move_id ? `#${move?.parent_move_id}` : `-`}</div>
                </div>
              </>
            ) : null}
          </Grid>

          {debug ? (
            <Grid item xs className={cls.ttList}>
              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Active:</div>
                <div className={cls.ttValFade}>{getFormattedActiveFlagFromMove(move)}</div>
              </div>

              {move?.chargeable === false || move?.chargeable === true ? (
                <div className={cls.ttFlex}>
                  <div className={cls.ttKeyFade}>Chargeable:</div>
                  <div className={cls.ttValFade}>{getFormattedChargeableFlagFromMove(move)}</div>
                </div>
              ) : null}

              {move?.payable === false || move?.payable === true ? (
                <div className={cls.ttFlex}>
                  <div className={cls.ttKeyFade}>Payable:</div>
                  <div className={cls.ttValFade}>{getFormattedPayableFlagFromMove(move)}</div>
                </div>
              ) : null}

              {move?.settled === false || move?.settled === true ? (
                <div className={cls.ttFlex}>
                  <div className={cls.ttKeyFade}>Settled:</div>
                  <div className={cls.ttValFade}>{getFormattedSettledFlagFromMove(move)}</div>
                </div>
              ) : null}

              {move?.class ? (
                <>
                  <hr className={cls.ttDivider} />
                  <div className={cls.ttFlex}>
                    <div className={cls.ttKeyFade}>Class:</div>
                    <div className={cls.ttValFade}>{capFirst(move?.class) || `-`}</div>
                  </div>
                </>
              ) : null}

              {move?.pickup_workflow_id || move?.delivery_workflow_id ? (
                <>
                  <hr className={cls.ttDivider} />

                  {move?.pickup_workflow_id ? (
                    <div className={cls.ttFlex}>
                      <div className={cls.ttKeyFade}>Pickup Workflow ID:</div>
                      <div className={cls.ttValFade}>{move?.pickup_workflow_id || `-`}</div>
                    </div>
                  ) : null}

                  {move?.delivery_workflow_id ? (
                    <div className={cls.ttFlex}>
                      <div className={cls.ttKeyFade}>Delivery Workflow ID:</div>
                      <div className={cls.ttValFade}>{move?.delivery_workflow_id || `-`}</div>
                    </div>
                  ) : null}
                </>
              ) : null}

              {move?.synced_with_tookan ? (
                <>
                  <hr className={cls.ttDivider} />

                  <div className={cls.ttFlex}>
                    <div className={cls.ttKeyFade}>Last Synced At:</div>
                    <div className={cls.ttValFade}>{move?.synced_with_tookan || `-`}</div>
                  </div>
                </>
              ) : null}

              <hr className={cls.ttDivider} />

              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Last Updated At:</div>
                <div className={cls.ttValFade}>{dayjs(move?.updatedat).format(`M/D/YYYY h:mm A`)}</div>
              </div>

              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Last Updated By:</div>
                <div className={cls.ttValFade}>{move?.updatedBy || `-`}</div>
              </div>

              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Created At:</div>
                <div className={cls.ttValFade}>{dayjs(move?.createdat).format(`M/D/YYYY h:mm A`)}</div>
              </div>

              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Created By:</div>
                <div className={cls.ttValFade}>{move?.createdBy || `-`}</div>
              </div>
            </Grid>
          ) : null}
        </Grid>
      }
    >
      {children}
    </Tooltip>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  ttRoot: {},

  ttList: {},

  ttFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minWidth: 192,
    maxWidth: 384,
    color: theme.palette.text.contrast,
  },

  ttKey: {
    marginRight: 8,
    fontWeight: 300,
  },
  ttKeyFade: {
    marginRight: 4,
    fontWeight: 300,
    color: theme.palette.text.contrastFade,
  },

  ttVal: {
    fontWeight: 500,
  },
  ttValFade: {
    fontWeight: 500,
    color: theme.palette.text.contrastFade,
  },
  ttValBold: {
    fontWeight: 900,
  },

  ttDivider: {
    width: '100%',
    opacity: 0.5,
  },
}));
