// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useTheme, makeStyles, Chip, Icon, Tooltip, Typography } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

import { Table, Column } from '../../components/TableComponents';
import DriverIncentiveModal from '../../components/DriverIncentiveModal/DriverIncentiveModal';
import DriverChargeModal from '../../components/DriverChargeModal/DriverChargeModal';

import APDetailTable from './APDetailTable';

// COMPONENT ---------------------------------------------------------------- //

export default function PayCycleDetailsTable({
  paycycle,
  driverPayArray,
  payMode,
  checkedDriverPayArray,
  setCheckedDriverPayArray,
  refetch,
}) {
  const theme = useTheme();
  const cls = useStyles();

  const { capFirst, getInitialsFromName, formatUSD } = useTools();

  const [filteredDriverPay, setFilteredDriverPay] = React.useState([]);
  const [search, setSearch] = React.useState('');

  // Driver Incentive Modal
  const [driverIncentiveModal, setDriverIncentiveModal] = React.useState({ open: false, input: null, output: null });
  const handleDriverIncentiveModalOpen = (driverId, driverName, taxClass, type, incentive = null, editMode = false) => {
    setDriverIncentiveModal({
      open: true,
      type: type,
      editMode: editMode,
      input: { driverId, driverName, taxClass, incentive },
    });
  };
  const handleDriverIncentiveModalClose = (output = null) => {
    if (output) setDriverIncentiveModal({ open: false, input: null, output: output });
    else setDriverIncentiveModal({ open: false, input: null, type: null, editMode: false });

    if (refetch) refetch();
  };

  // Driver Charge Modal
  const [driverChargeModal, setDriverChargeModal] = React.useState({
    open: false,
    input: null,
    output: null,
    editMode: false,
  });
  const handleDriverChargeModalOpen = (driverId, driverName, taxClass, charge = null, editMode = false) => {
    setDriverChargeModal({
      open: true,
      editMode: editMode,
      input: { driverId, driverName, taxClass, charge },
    });
  };
  const handleDriverChargeModalClose = (output = null) => {
    if (output) setDriverChargeModal({ open: false, input: null, output: output, editMode: false });
    else setDriverChargeModal({ open: false, input: null, editMode: false });

    if (refetch) refetch();
  };

  const handleLocalFilters = () => {
    let filteredData = [...driverPayArray];

    if (driverPayArray?.length) {
      // Check search filter
      if (search?.length) {
        const s = search?.toLowerCase()?.trim();
        filteredData = filteredData?.filter(
          fd => `${fd?.driver_id}`?.includes(s) || fd?.driver_name?.toLowerCase()?.includes(s)
        );
      }
    }

    setFilteredDriverPay(filteredData);
  };

  React.useEffect(() => {
    handleLocalFilters();
    // eslint-disable-next-line
  }, [driverPayArray, search]);

  const getStatusChipClass = status => {
    switch (status) {
      case `unpaid`:
        return cls.statusUnpaid;
      case `partial`:
        return cls.statusPartial;
      case `paid`:
        return cls.statusPaid;
      default:
        return undefined;
    }
  };

  const getStatusChipIcon = status => {
    switch (status) {
      case `unpaid`:
        return `remove_circle_outline`;
      case `partial`:
        return `contrast`;
      case `paid`:
        return `check_circle`;
      default:
        return undefined;
    }
  };

  const rowActions = [
    {
      label: `Add Bonus`,
      handler: driverPay =>
        handleDriverIncentiveModalOpen(driverPay?.driver_id, driverPay?.driver_name, driverPay?.tax_class, 'bonus'),
    },
    {
      label: `Add Promo`,
      handler: driverPay =>
        handleDriverIncentiveModalOpen(driverPay?.driver_id, driverPay?.driver_name, driverPay?.tax_class, 'promo'),
    },
    {
      label: `Add Charge`,
      handler: driverPay =>
        handleDriverChargeModalOpen(driverPay?.driver_id, driverPay?.driver_name, driverPay?.tax_class),
    },
  ];

  const buildRowActions = driverPay => {
    return [
      {
        label: `Add Bonus`,
        handler: () =>
          handleDriverIncentiveModalOpen(driverPay?.driver_id, driverPay?.driver_name, driverPay?.tax_class, 'bonus'),
      },
      {
        label: `Add Promo`,
        handler: () =>
          handleDriverIncentiveModalOpen(driverPay?.driver_id, driverPay?.driver_name, driverPay?.tax_class, 'promo'),
      },
      {
        label: `Add Charge`,
        handler: () => handleDriverChargeModalOpen(driverPay?.driver_id, driverPay?.driver_name, driverPay?.tax_class),
      },
    ];
  };

  return (
    <>
      <DriverIncentiveModal
        open={driverIncentiveModal.open}
        input={driverIncentiveModal.input}
        type={driverIncentiveModal.type}
        editMode={driverIncentiveModal.editMode}
        close={handleDriverIncentiveModalClose}
      />
      <DriverChargeModal
        open={driverChargeModal.open}
        input={driverChargeModal.input}
        editMode={driverChargeModal.editMode}
        close={handleDriverChargeModalClose}
      />

      <div className={cls.wrapper}>
        <Table
          data={filteredDriverPay}
          rowActions={rowActions}
          defaultOrderBy={'driver'}
          defaultOrder={'asc'}
          tableAriaLabel={`pay-cycle-details`}
          stickyHeader={true}
          usePagination={false}
          checkedItems={checkedDriverPayArray}
          setCheckedItems={setCheckedDriverPayArray}
          foldComponent={driverPay => (
            <APDetailTable
              paycycle={paycycle}
              driverPay={driverPay}
              actions={buildRowActions(driverPay)}
              handleDriverChargeModalOpen={handleDriverChargeModalOpen}
              handleDriverIncentiveModalOpen={handleDriverIncentiveModalOpen}
              handleDriverPayModalClose={handleDriverChargeModalClose}
              handleDriverIncentiveModalClose={handleDriverIncentiveModalClose}
              driverIncentiveModal={driverIncentiveModal}
              driverChargeModal={driverChargeModal}
            />
          )}
          search={search}
          setSearch={setSearch}
        >
          {payMode ? (
            <Column
              name={`checkbox`}
              value={driverPay => driverPay.driver_id}
              label={`Checkbox`}
              tooltip={`Select a driver`}
              checkbox={true}
            />
          ) : null}

          <Column
            name={`avatar`}
            label={`Avatar`}
            tooltip={`Avatar of the driver`}
            value={driverPay => driverPay.driver_avatar_url}
            renderer={driverPay =>
              driverPay.driver_avatar_url ? (
                <div className={cls.avatar}>
                  <img className={cls.avatarImg} src={driverPay.driver_avatar_url} alt='avatar' />
                </div>
              ) : (
                <div className={cls.avatar}>
                  <div className={cls.avatarImg}>
                    <Typography className={cls.avatarInitialsTxt}>
                      {getInitialsFromName({ driver_name: driverPay.driver_name || null })}
                    </Typography>
                  </div>
                </div>
              )
            }
            style={{ minWidth: 80 }}
          />

          <Column
            name={`driver`}
            label={`Driver`}
            tooltip={`Display name of the driver`}
            value={driverPay => driverPay.driver_name}
            renderer={driverPay => `${driverPay.driver_name} (${driverPay.driver_id})`}
            style={{ minWidth: 80 }}
          />

          <Column
            name={`dd`}
            label={`DD`}
            tooltip={`Does the driver have Direct Deposit setup?`}
            value={driverPay => driverPay.driver_bank_account_token}
            renderer={driverPay =>
              driverPay.driver_bank_account_token ? (
                <Tooltip title='Direct deposit ready'>
                  <Icon style={{ display: `block`, color: theme.palette.text.disabled }}>wallet</Icon>
                </Tooltip>
              ) : null
            }
            style={{ minWidth: 80 }}
          />

          <Column
            name={`payment_count`}
            label={`Payment Count`}
            tooltip={`Number of payments accumulated by the driver during the pay cycle`}
            value={driverPay => driverPay.payment_count}
            renderer={driverPay => `${driverPay.payment_count} Payment${driverPay.payment_count === 1 ? `` : `s`}`}
            style={{ minWidth: 80 }}
          />

          <Column
            name={`charge_count`}
            label={`Charge Count`}
            tooltip={`Number of charges accumulated by the driver during the pay cycle`}
            value={driverPay => driverPay.charge_count}
            renderer={driverPay => `${driverPay.charge_count} Charge${driverPay.charge_count === 1 ? `` : `s`}`}
            style={{ minWidth: 80 }}
          />

          <Column
            name={`status`}
            label={`Status`}
            tooltip={`Has the driver been paid for the pay cycle?`}
            value={driverPay => driverPay.status}
            renderer={driverPay => (
              <Chip
                className={getStatusChipClass(driverPay.status)}
                size='small'
                color='primary'
                label={capFirst(driverPay.status)}
                icon={<Icon className={cls.chipIcon}>{getStatusChipIcon(driverPay.status)}</Icon>}
              />
            )}
            style={{ minWidth: 100 }}
          />

          <Column
            name={`notes`}
            label={`Notes`}
            tooltip={`Notes or issues relating to this driver's pay cycle`}
            value={driverPay => driverPay.status}
            renderer={driverPay => {
              const newPayouts = [];
              const processingPayouts = [];
              const canceledPayouts = [];
              const failedPayouts = [];
              driverPay.driverpayouts.forEach(dpo => {
                if (dpo.status && dpo.status === `new`) newPayouts.push(dpo);
                if (dpo.status && dpo.status === `processing`) processingPayouts.push(dpo);
                if (dpo.status && dpo.status === `canceled`) canceledPayouts.push(dpo);
                if (dpo.status && dpo.status === `failed`) failedPayouts.push(dpo);
              });

              return (
                <div className={cls.notes}>
                  {newPayouts?.length
                    ? newPayouts?.map(npo => (
                        <Chip
                          key={npo.id}
                          className={cls.chipNew}
                          size='small'
                          label={`Contains ${newPayouts?.length} new payout${newPayouts?.length !== 1 ? `s` : ``}`}
                        />
                      ))
                    : null}

                  {processingPayouts?.length
                    ? processingPayouts?.map(ppo => (
                        <Chip
                          key={ppo.id}
                          className={cls.chipProcessing}
                          size='small'
                          label={`Contains ${processingPayouts?.length} processing payout${
                            processingPayouts?.length !== 1 ? `s` : ``
                          }`}
                        />
                      ))
                    : null}

                  {canceledPayouts?.length
                    ? canceledPayouts?.map(cpo => (
                        <Chip
                          key={cpo.id}
                          className={cls.chipIssue}
                          size='small'
                          label={`Contains ${canceledPayouts?.length} canceled payout${
                            canceledPayouts?.length !== 1 ? `s` : ``
                          }`}
                        />
                      ))
                    : null}

                  {failedPayouts?.length
                    ? failedPayouts?.map(fpo => (
                        <Chip
                          key={fpo.id}
                          className={cls.chipIssue}
                          size='small'
                          label={`Contains ${failedPayouts?.length} failed payout${
                            failedPayouts?.length !== 1 ? `s` : ``
                          }`}
                        />
                      ))
                    : null}

                  {!newPayouts?.length &&
                  !processingPayouts?.length &&
                  !canceledPayouts?.length &&
                  !failedPayouts?.length
                    ? `-`
                    : null}
                </div>
              );
            }}
            style={{ minWidth: 100 }}
          />

          <Column
            name={`amount_paid`}
            label={`Amount Paid`}
            tooltip={`Total amount paid to the driver for the pay cycle`}
            value={driverPay => driverPay.amount_paid}
            renderer={driverPay => (
              <Typography className={driverPay?.amount_paid < 0 ? cls.amountTxtNegative : cls.amountTxt}>
                {formatUSD(driverPay.amount_paid, { displayAccountingNegative: true })}
              </Typography>
            )}
            style={{ minWidth: 80 }}
            align={`right`}
          />

          <Column
            name={`amount_due`}
            label={`Amount Due`}
            tooltip={`Total amount owed to the driver for the pay cycle`}
            value={driverPay => driverPay.amount_due}
            renderer={driverPay => (
              <Typography className={driverPay?.amount_due < 0 ? cls.amountTxtNegative : cls.amountTxt}>
                {formatUSD(driverPay.amount_due, { displayAccountingNegative: true })}
              </Typography>
            )}
            style={{ minWidth: 80 }}
            align={`right`}
          />
        </Table>
      </div>
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflowY: `auto`,
    borderBottom: theme.border[0],
    backgroundColor: theme.palette.background.paper,
  },

  avatar: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
  avatarInitialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },

  notes: {
    display: `flex`,
    flexWrap: `wrap`,
    gap: 4,
  },

  chipNew: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  chipProcessing: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  chipIssue: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },

  chipIcon: {
    fontSize: 18,
  },

  statusUnpaid: {
    backgroundColor: theme.palette.utility.lighter,
    color: theme.palette.utility.contrastText,
  },
  statusPartial: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  statusPaid: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },

  amountTxt: {
    fontSize: 14,
    fontWeight: 500,
  },
  amountTxtNegative: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
}));
