import { gql } from 'graphql-tag';

/////////////////////// QUERIES ///////////////////////

export const queries = {
  UNASSIGN_HANGTAG: gql`
    mutation admin_plans_unassignHangtag($hangtagId: bigint!) {
      update_hangtags(
        where: { id: { _eq: $hangtagId } }
        _set: { move_id: null, status: "unassigned", workflow_data: null }
      ) {
        affected_rows
        returning {
          id
          move_id
          status
          workflow_data
        }
      }
    }
  `,

  SUBSCRIBE_TO_CANCELED_MOVES_CHANGES_BY_DATE: gql`
    subscription admin_global_subscribeToCanceledMovesChangesByDate($start: timestamptz!, $end: timestamptz!) {
      canceledMoves: moves(
        order_by: { pickup_time: asc_nulls_last }
        where: {
          _and: {
            active: { _eq: 1 }
            customer_id: { _is_null: false }
            lane_id: { _is_null: false }
            move_type: { _eq: "drive" }
            cancel_status: { _is_null: false }
            pickup_time: { _gte: $start, _lte: $end }
          }
        }
      ) {
        id
      }
    }
  `,

  GET_CANCELED_MOVE_DETAILS_BY_IDS: gql`
    query admin_global_getCanceledMoveDetailsByIds($start: timestamptz!, $end: timestamptz!) {
      canceledMoves: moves(
        order_by: { pickup_time: asc_nulls_last }
        where: {
          _and: {
            active: { _eq: 1 }
            customer_id: { _is_null: false }
            lane_id: { _is_null: false }
            move_type: { _eq: "drive" }
            cancel_status: { _is_null: false }
            pickup_time: { _gte: $start, _lte: $end }
          }
        }
      ) {
        id
        active
        cancel_status
        chargeable
        class
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        customer_id
        dealer_contact
        deliver_by
        delivery_arrived
        delivery_started
        delivery_stop_id
        delivery_successful
        delivery_time
        driver_app_version
        driver_id
        driver_name
        driver_status
        lane_id
        lyft_flag
        lyft_trigger_id
        manual_flag
        move_details
        move_failed
        move_type
        pickup_arrived
        pickup_started
        pickup_stop_id
        pickup_successful
        pickup_time
        pinnable
        plan_id
        rate_class_override
        ready_by
        reference_num
        return_ride_id
        ride_type
        sequence
        status
        synced_with_tookan
        tags
        updatedat
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
        lane {
          id
          description
          pickup {
            id
            region_id
          }
          delivery {
            id
            region_id
          }
        }
        customer {
          id
          name
        }
      }
    }
  `,

  SUBSCRIBE_TO_UNASSIGNED_MOVES_CHANGES: gql`
    subscription admin_global_subscribeToUnassignedMovesChanges {
      moves(
        order_by: { pickup_time: asc_nulls_last }
        where: {
          active: { _eq: 1 }
          customer_id: { _is_null: false }
          lane_id: { _is_null: false }
          move_type: { _eq: "drive" }
          plan_id: { _is_null: true }
          cancel_status: { _is_null: true }
          _or: [{ driver_id: { _is_null: true } }, { status: { _is_null: true } }]
        }
      ) {
        id
        deliver_by
        ready_by
        updatedat
        lane {
          id
          updatedat
        }
        hangtags {
          id
          status
          updated_at
          workflow_data
        }
      }
    }
  `,

  GET_UNASSIGNED_MOVE_DETAILS: gql`
    query admin_global_getUnassignedMoveDetails {
      moves(
        order_by: { sequence: asc_nulls_last }
        where: {
          active: { _eq: 1 }
          customer_id: { _is_null: false }
          lane_id: { _is_null: false }
          move_type: { _eq: "drive" }
          plan_id: { _is_null: true }
          cancel_status: { _is_null: true }
          _or: [{ driver_id: { _is_null: true } }, { status: { _is_null: true } }]
        }
      ) {
        id
        active
        cancel_status
        chargeable
        class
        config
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        customer_id
        dealer_contact
        deliver_by
        delivery_arrived
        delivery_started
        delivery_stop_id
        delivery_successful
        delivery_time
        driver_app_version
        driver_id
        driver_name
        driver_status
        lane_id
        lyft_flag
        lyft_trigger_id
        manual_flag
        move_details
        move_failed
        move_type
        pickup_arrived
        pickup_started
        pickup_stop_id
        pickup_successful
        pickup_time
        plan_id
        rate_class_override
        ready_by
        reference_num
        return_ride_id
        ride_type
        sequence
        status
        synced_with_tookan
        tags
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
        accessorials {
          id
          move_id
          code
          status
          notes
          cost
          ap_amount
          ar_amount
        }
        customer {
          id
          name
          config
          organization {
            id
            name
            config
          }
        }
        lane {
          id
          description
          distance_miles
          duration_sec
          pickup_inspection_sec
          delivery_inspection_sec
          return_ride_wait_sec
          origin_location_id
          destination_location_id
          estimated_rideshare_return_cost
          customer {
            raterulegroups(where: { _and: { begin_date: { _lte: "now()" }, end_date: { _gte: "now()" } } }) {
              ridesharerate {
                description
                effective_date
                expiration_date
                id
                initial_cost
                maximum_ride_cost
                minimum_ride_cost
                name
                per_mile_rate
                per_minute_rate
              }
            }
          }
          pickup {
            id
            address
            email
            latitude
            longitude
            name
            nickname
            phone
            region_id
            region {
              id
              name
            }
            timezone
          }
          delivery {
            id
            address
            email
            latitude
            longitude
            name
            nickname
            phone
            region_id
            region {
              id
              name
            }
            timezone
          }
        }
        lyftride {
          move_id
          activeAttempt {
            id
            estimated_ride_cost
            ride_cost
          }
        }
        parent_move_id
        parentMove {
          id
          move_type
          consumer_name
          consumer_phone
          consumer_pickup
          pickup_time
        }
        childMoves {
          id
          move_type
          consumer_name
          consumer_phone
          consumer_pickup
          pickup_time
          active
        }
        parent_move {
          id
          move_type
          consumer_name
          consumer_phone
          consumer_pickup
          pickup_time
        }
        moveByReturnRideId {
          id
          move_type
          consumer_name
          consumer_phone
          consumer_pickup
          pickup_time
        }
        hangtags(order_by: { updated_at: desc }) {
          id
          customer {
            id
            name
          }
          driver_id
          hash_id
          print_layout
          rear_code
          status
          type
          updated_at
          updated_by
          url
          workflow_data
          workflow_id
        }
        groupable_moves {
          id
        }
      }
    }
  `,

  SUBSCRIBE_TO_PLAN_CHANGES: gql`
    subscription admin_timeline_subscribeToPlanChanges($plan_date: date!) {
      plans(
        where: { plan_date: { _eq: $plan_date } }
        order_by: [{ region: { name: asc_nulls_first } }, { driver_name: asc_nulls_first }]
      ) {
        id
        updatedat
        moves(where: { active: { _eq: 1 } }, order_by: { pickup_time: asc_nulls_last }) {
          id
          cancel_status
          deliver_by
          delivery_arrived
          delivery_started
          delivery_stop_id
          delivery_successful
          delivery_time
          driver_app_version
          driver_status
          pickup_arrived
          pickup_started
          pickup_stop_id
          pickup_successful
          pickup_time
          ready_by
          status
          synced_with_tookan
          updatedat
          lane {
            id
            updatedat
          }
          hangtags {
            id
            status
            updated_at
            workflow_data
          }
        }
      }
    }
  `,

  GET_DETAILED_PLANS_BY_DATE: gql`
    query admin_timeline_getPlanDetailsByDate($plan_date: date!) {
      plans(
        where: { plan_date: { _eq: $plan_date } }
        order_by: [{ region: { name: asc_nulls_first } }, { driver_name: asc_nulls_first }]
      ) {
        id
        createdat
        driver_id
        driver_name
        parked_location
        plan_date
        region_id
        updatedat
        driver {
          id
          driver_app_version
          region_id
        }
        region {
          id
          name
        }
        moves(where: { active: { _eq: 1 } }, order_by: { pickup_time: asc_nulls_last }) {
          id
          active
          actual_delivery_mileage
          actual_pickup_mileage
          auto_assign
          cancel_status
          chargeable
          class
          config
          consumer_at_pickup
          consumer_name
          consumer_phone
          consumer_pickup
          consumer_type
          createdat
          customer_id
          dealer_contact
          deliver_by
          delivery_arrived
          delivery_started
          delivery_stop_id
          delivery_successful
          delivery_time
          delivery_workflow_id
          driver_app_version
          driver_id
          driver_name
          driver_status
          lane_id
          lyft_flag
          lyft_trigger_id
          manual_flag
          move_details
          move_failed
          move_type
          payable
          pickup_arrived
          pickup_started
          pickup_stop_id
          pickup_successful
          pickup_time
          pickup_workflow_id
          pinnable
          plan_id
          priority
          rate_class_override
          ready_by
          reference_num
          return_ride_id
          ride_type
          sequence
          settled
          status
          synced_with_tookan
          tags
          tookan_relationship_id
          tracking_link
          updatedat
          vehicle_color
          vehicle_image
          vehicle_make
          vehicle_model
          vehicle_odometer
          vehicle_stock
          vehicle_vin
          vehicle_year
          accessorials {
            id
            move_id
            code
            status
            notes
            cost
            ap_amount
            ar_amount
          }
          customer {
            id
            name
            config
            organization {
              id
              name
              config
            }
            raterulegroups(where: { _and: { begin_date: { _lte: "now()" }, end_date: { _gte: "now()" } } }) {
              ridesharerate {
                description
                effective_date
                expiration_date
                id
                initial_cost
                maximum_ride_cost
                minimum_ride_cost
                name
                per_mile_rate
                per_minute_rate
              }
            }
          }
          lane {
            id
            description
            duration_sec
            distance_miles
            tolls
            average_drive_speed_mph
            pickup_inspection_sec
            delivery_inspection_sec
            return_ride_wait_sec
            origin_location_id
            destination_location_id
            estimated_rideshare_return_cost
            customer_id
            customer {
              id
              name
              raterulegroups(where: { _and: { begin_date: { _lte: "now()" }, end_date: { _gte: "now()" } } }) {
                ridesharerate {
                  description
                  effective_date
                  expiration_date
                  id
                  initial_cost
                  maximum_ride_cost
                  minimum_ride_cost
                  name
                  per_mile_rate
                  per_minute_rate
                }
              }
            }
            pickup {
              id
              active
              address
              createdat
              customer_id
              email
              favorite
              latitude
              longitude
              name
              nickname
              phone
              notes
              place_id
              region_id
              region {
                id
                name
              }
              timezone
              tookan_id
              type
              updatedat
            }
            delivery {
              id
              active
              address
              createdat
              customer_id
              email
              favorite
              latitude
              longitude
              name
              nickname
              phone
              notes
              place_id
              region_id
              region {
                id
                name
              }
              timezone
              tookan_id
              type
              updatedat
            }
          }
          lyft_trigger_move {
            driver_name
          }
          movesByLyftTriggerId {
            id
            driver_id
            driver_name
          }
          parent_move_id
          parentMove {
            id
            cancel_status
            consumer_at_pickup
            consumer_name
            consumer_phone
            consumer_pickup
            consumer_type
            driver_name
            pickup_time
            status
            lane {
              id
              description
              duration_sec
              distance_miles
              tolls
              average_drive_speed_mph
              origin_location_id
              destination_location_id
              estimated_rideshare_return_cost
            }
          }
          childMoves {
            id
            move_type
            cancel_status
            consumer_at_pickup
            consumer_name
            consumer_phone
            consumer_pickup
            consumer_type
            driver_name
            pickup_time
            status
          }
          hangtags(order_by: { updated_at: desc }) {
            id
            customer {
              id
              name
            }
            driver_id
            hash_id
            print_layout
            rear_code
            status
            type
            updated_at
            updated_by
            url
            workflow_data
            workflow_id
          }
          hadHangtag: eventlogs_aggregate(where: { actiontype: { name: { _eq: "hangtag.assigned" } } }) {
            aggregate {
              count
            }
          }
          ridegroup {
            id
            group_id
            ready_at_time
            status
          }
        }
      }
    }
  `,
};
