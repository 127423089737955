//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid } from '@material-ui/core/';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomerMultiselect from '../../components/CustomerMultiselect/CustomerMultiselect';
import { useInvoices } from './useInvoices';

//////////////////////// COMPONENT ////////////////////////

export default function InvoicesListFilter({ invoiceIdInURL, start, end, onDateChange, handleChangeCustomers }) {
  const cls = useStyles();
  const { getDefaultSelectedCustomerIds } = useInvoices();

  const [localCustomerIdArray, setLocalCustomerIdArray] = React.useState([]);

  const handleDateChange = name => event => {
    if (onDateChange) onDateChange(event, name);
  };

  React.useEffect(() => {
    let customerIdArr = getDefaultSelectedCustomerIds();
    setLocalCustomerIdArray(customerIdArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cls.filter}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomerMultiselect
              selectedCustomerIdArray={localCustomerIdArray || []}
              handleChangeCustomers={handleChangeCustomers}
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              autoOk
              maxDate={end}
              label='Start Date'
              format='MM/dd/yyyy'
              orientation='portrait'
              size='small'
              variant='inline'
              inputVariant='outlined'
              value={start}
              onChange={handleDateChange(`start`)}
              className={cls.date}
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              autoOk
              minDate={start}
              label='End Date'
              format='MM/dd/yyyy'
              orientation='portrait'
              size='small'
              variant='inline'
              inputVariant='outlined'
              value={end}
              onChange={handleDateChange(`end`)}
              className={cls.date}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  filter: {
    position: 'relative',
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
    marginTop: theme.spacing(1),
  },
  date: {
    width: '100%',
  },
}));
